import React, { useEffect, useMemo, useState } from 'react';
import { Container, Divider, Flex, Space, Title, TitleOrder, Input } from '@mantine/core';
import { Button } from '@unserkunde/enscompare-components/src/components/components';
import { InputRow } from '../shared/Common';
import {
  HookedCheckboxRow,
  HookedRadioSwitchRow,
  HookedSelect,
  HookedTextInput,
  HookedDateInput,
  useMantineLabelProps,
  HookedAutocompleteDynamicInput,
  HookedDevicemodeSelect,
} from '../shared/HookedInputs';
import { useAppDispatch, useAppSelector, useUserData } from '../../hooks';
import FormularEvaluate, { GetEvaluationContext } from '../../lib/FormularEvaluate';
import { DynamicEntryQuestionType } from '../../actions/types/DynamicEntryQuestionType';
import { LinkButton, PureHtml } from './style/components';
import { BikeUserDataType, dynamicObjectStartValue } from '@/reducer/userData';
import { ChangePropTargetField, changeUserInput } from '@/actions/form';
import { GetIsDevMode } from './IsDevMode';

const useCustomSettings = (settingsText) => {
  return useMemo(() => {
    const lines = (settingsText || '').split('\r\n');
    const settings = lines.map((l) => l.split('=')).filter((o) => o.length === 2);
    return settings.reduce((prev, current) => ({ ...prev, [current[0]]: current[1] }), {});
  }, [settingsText]);
};

const useValueList = (valueText, reverse = false) => {
  return useMemo(() => {
    const result = valueText.split(/\r?\n/);
    let values = result.map((s) => s.split(':'));

    if (reverse) values = values.map((v) => v.reverse());

    return values;
  }, [valueText]);
};

type DynamicInputComponentProps = {
  setting: DynamicEntryQuestionType;
  mantine?: boolean;
  disableWrap?: boolean;
  [key: string]: any;
};

const DynamicTextQuestion = ({ setting, ...props }: DynamicInputComponentProps) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedTextInput
      field={setting.name}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicAutcompleteQuestion = ({ setting, ...props }: DynamicInputComponentProps) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedAutocompleteDynamicInput
      field={setting.name}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicDateQuestion = ({ setting, ...props }: DynamicInputComponentProps) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedDateInput
      field={setting.name}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicSelectSearchQuestion = ({ setting, ...props }) => {
  return (
    <DynamicSelectQuestion
      {...props}
      setting={setting}
      searchable
    />
  );
};

const DynamicSelectQuestion = ({ setting, ...props }) => {
  const values = useValueList(setting.values, false);

  return (
    <HookedSelect
      field={setting.name}
      values={values}
      defaultValue={values.length === 0 ? null : values[0][0]}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicSelectDeviceTypes = ({ setting, ...props }: { setting: DynamicEntryQuestionType; [x: string]: any }) => {
  const { hideOption, ...settingsObj } = useCustomSettings(setting.values);

  const hideValues = useMemo(() => (hideOption ? hideOption.split(',') : []), [hideOption]);

  return (
    <HookedDevicemodeSelect
      field={setting.name}
      hideOptions={hideValues}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicRadioQuestion = ({ setting, ...props }) => {
  const values = useValueList(setting.values, true);

  return (
    <HookedRadioSwitchRow
      title={setting.displayName}
      helpText={setting.helpText}
      field={setting.name}
      values={values}
      defaultValue={values.length === 0 ? null : values[0][1]}
      {...props}
    />
  );
};

const DynamicCheckboxQuestion = ({ setting, ...props }) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedCheckboxRow
      field={setting.name}
      mode={''}
      title={setting.displayName}
      helpText={setting.helpText}
      {...settingsObj}
      {...props}
    />
  );
};

const DynamicConfirmationQuestion = ({ setting, ...props }) => {
  return (
    <HookedCheckboxRow
      field={setting.name}
      helpText={setting.helpText}
      noWrapWithTitle
      {...props}
      title={setting?.displayName}
      label={<PureHtml content={setting.values} />}
    />
  );
};

const DynamicInfotext = ({ setting }) => {
  return (
    <Input.Wrapper>
      <PureHtml content={setting.values} />
    </Input.Wrapper>
  );

  return (
    <InputRow
      title={setting.displayName}
      helpText={setting.helpText}>
      <PureHtml content={setting.values} />
    </InputRow>
  );
};

const DynamicInfotextToggle = ({ setting }) => {
  const [isOpen, setIsOpen] = useState(false);

  const displayTexts = setting.displayName.split('|');

  return (
    <InputRow
      title=''
      helpText={setting.helpText}>
      <div>
        <LinkButton onClick={() => setIsOpen(!isOpen)}>
          {displayTexts.length === 1 ? displayTexts[0] : displayTexts[isOpen ? 1 : 0]}
        </LinkButton>

        {!isOpen ? null : <PureHtml content={setting.values} />}
      </div>
    </InputRow>
  );
};

const DynamicHeader = ({ setting }) => {
  const settingsObj = useCustomSettings(setting.values);
  const { label } = useMantineLabelProps(setting.displayName, setting.helpText);

  return (
    <div>
      <Title
        mt={'md'}
        order={settingsObj?.order ? (parseInt(settingsObj.order) as TitleOrder) : 2}>
        {label}
      </Title>
      <Divider mt={0} />
    </div>
  );
};

const DynamicSelectButtons = ({ setting, bikeId }) => {
  const values = useMemo(() => {
    const result = setting.values.split(/\r?\n/);
    return result.map((s) => s.split(':'));
  }, [setting.values]);

  const [_, onChange] = useUserData(setting.name, bikeId);

  return (
    <Flex
      gap='xs'
      wrap='wrap'>
      {values.map((v) => (
        <Button
          key={v[0]}
          size={'xs'}
          onClick={() => onChange(v[0])}>
          {v[1]}
        </Button>
      ))}
    </Flex>
  );
};

export const evaluateFilter = (
  dynamicFieldSetting: DynamicEntryQuestionType,
  bikeId: string | null,
  state: RootState
) => {
  if (!dynamicFieldSetting.displayFilter) return true;

  const context = GetEvaluationContext(state, bikeId);

  const res = FormularEvaluate(dynamicFieldSetting.displayFilter, context);

  return res;
};

export const useDisplayFilter = (setting: DynamicEntryQuestionType, bikeId: string) => {
  const state = useAppSelector((state) => state);
  return evaluateFilter(setting, bikeId, state);
};

export const useStartValue = (enabled: boolean, setting: DynamicEntryQuestionType, bikeId: string | null) => {
  const [initilized, setInitilized] = useState(false);
  const dispatch = useAppDispatch();
  const currentValue = useAppSelector((state) =>
    bikeId ? state.userData.bikes[bikeId][setting.name] : state.userData[setting.name]
  );

  useEffect(() => {
    if (initilized || !enabled) return;

    const startSource = setting.positionType === 'object' ? dynamicObjectStartValue : {};
    if (!startSource[setting.name] || currentValue) {
      setInitilized(true);
      return;
    }

    const startValue = startSource[setting.name];

    if (GetIsDevMode()) console.log('Loading dynamic start value for ' + setting.name + ' with', startValue);

    if (bikeId) dispatch(changeUserInput(setting.name as keyof BikeUserDataType, startValue, bikeId));
    else dispatch(changeUserInput(setting.name as keyof UserDataType, startValue));

    setInitilized(true);
  }, [enabled, initilized, setting, bikeId, currentValue]);
};

const DynamicEntryQuestion = <T extends React.PropsWithChildren>({
  setting,
  wrapper = null,
  wrapperProps = undefined,
  ...props
}: {
  setting: DynamicEntryQuestionType;
  wrapper?: React.ComponentType<T> | null;
  wrapperProps?: Omit<T, 'children'>;
  [x: string]: any;
} & T) => {
  const { fieldType } = setting;

  const Control = useMemo(() => {
    if (fieldType === 'text') return DynamicTextQuestion;
    if (fieldType === 'date') return DynamicDateQuestion;
    if (fieldType === 'select') return DynamicSelectQuestion;
    if (fieldType === 'selectSearch') return DynamicSelectSearchQuestion;
    if (fieldType === 'radio') return DynamicRadioQuestion;
    if (fieldType === 'infoText') return DynamicInfotext;
    if (fieldType === 'toggleInfoText') return DynamicInfotextToggle;
    if (fieldType === 'checkbox') return DynamicCheckboxQuestion;
    if (fieldType === 'confirmation') return DynamicConfirmationQuestion;
    if (fieldType === 'selectButtons') return DynamicSelectButtons;
    if (fieldType === 'header') return DynamicHeader;
    if (fieldType === 'deviceTypes') return DynamicSelectDeviceTypes;
    if (fieldType === 'autocomplete') return DynamicAutcompleteQuestion;
    // eslint-disable-next-line react/display-name
    return () => <span>Entry option Type not found</span>;
  }, [fieldType, props.bikeId]);

  const show = useDisplayFilter(setting, props.bikeId);

  useStartValue(show, setting, props.bikeId);

  if (!show) return null;

  // @ts-ignore
  const inputControl = (
    <Control
      {...props}
      bikeId={props.bikeId}
      setting={setting}
    />
  );

  if (!wrapper) return inputControl;

  const Wrapper = wrapper;
  // @ts-ignore
  return <Wrapper {...wrapperProps}>{inputControl}</Wrapper>;
};

export default DynamicEntryQuestion;

export const DynamicEntryQuestionDisplay = ({ setting, bikeId = null }) => {
  const { fieldType, name } = setting;

  const [value] = useUserData(name, bikeId);
  const values = useValueList(setting.values);

  let result = '';

  switch (fieldType) {
    case 'select':
    case 'selectSearch':
    case 'radio':
      const hit = values.find((v) => v[0] === value);
      result = !hit ? value : hit[1];
      break;
    case 'checkbox':
    case 'confirmation':
      result = value ? 'Ja' : 'Nein';
      break;
    default:
      result = value;
      break;
  }

  return <>{result || ''}</>;
};

export const EntryQuestionContainer = ({
  children,
  ...props
}: React.ComponentProps<typeof Container> & { [s: string]: any }) => {
  return (
    <Container
      px={0}
      {...props}>
      <Flex
        gap='md'
        direction='column'>
        {children}
      </Flex>
      <Space h='lg' />
    </Container>
  );
};

import React from 'react';
import { RendererFromProcess } from './EnsProcess';
import { EnsProcessContextProvider } from './DefaultEnsProcessContext';
import { type FluentProgress } from '../defaultFluentProcess/FluentProgress';
import { FluentRenderComponent } from './FluentRenderComponent';

export const DefaultEnsProcessRenderer: RendererFromProcess<typeof FluentProgress> = (props) => {
  const step = props.processDefinition.processSteps[props.target];

  if (!step) {
    console.log('Step not found', props.target, props.processDefinition);
    throw new Error(`Step ${props.target} not found in process definition`);
  }

  const Compoent = step.useFluentProgress ? FluentRenderComponent : step.component;

  return (
    <EnsProcessContextProvider value={props.processDefinition}>
      <Compoent />
    </EnsProcessContextProvider>
  );
};

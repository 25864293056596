import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import numeral from 'numeral';
import { Grid, Chip, Text, Loader } from '@mantine/core';
import { setProductState, useCustomProduct } from '@/reducer/customProducts';
import { useAppDispatch, useAppSelector, useUserData } from '@/hooks';
import { CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { frequentDisplayName } from '../shared/Common';
import { useIsSize } from '@/hooks/theme';
import { Checklist } from '@unserkunde/enscompare-components';
import { GpsTrackerHeadpoints } from '../success/GpsSelectionCard';
import { usePremiumserviceChecklist } from '../InspectionVoucher/InspectionVoucherOrderComponent';
import { changeUserInput } from '@/actions/form';
import { reloadCustomProducts } from '@/reducer/customProducts/reloadCustomProducts';

export const CustomProductToggle = (props: { product: CustomProductNames }) => {
  const customProductInfos = useAppSelector((state) => state.customProducs.custom_products);
  const customProductInfosLoading = useAppSelector((state) => state.customProducs.custom_products_loading);

  const [product] = useCustomProduct(props.product);

  const seleted = product?.amount > 0;

  const dispatch = useAppDispatch();
  const onToggle = useCallback(() => {
    dispatch(setProductState(props.product, { amount: seleted ? 0 : 1 }));
  }, [seleted]);

  //Reload Products when paymentPeriod changes
  const paymentPeriod = useAppSelector((state) => state.userData.paymentPeriod);
  useEffect(() => {
    dispatch(reloadCustomProducts());
  }, [paymentPeriod]);

  if (!customProductInfos[props.product]) return null;

  return (
    <Chip
      color='green'
      variant='filled'
      w='100%'
      onChange={onToggle}
      checked={seleted}>
      {customProductInfos[props.product].displayName} {seleted ? 'hinzugefügt' : 'hinzufügen'} für{' '}
      {numeral(customProductInfos[props.product].price).format('0.00')} €{' '}
      <Text
        color={'gray'}
        style={{ display: 'inline' }}
        fz='0.7em'>
        {frequentDisplayName(customProductInfos[props.product].defaultPaymentPeriod)}
        {customProductInfosLoading && (
          <Loader
            ml='xs'
            size={10}
          />
        )}
      </Text>
    </Chip>
  );
};

export const PremiumserviceInEnsuranceList = () => {
  const spanOptions: React.ComponentProps<typeof Grid.Col> = {
    span: 12,
    sm: 6,
  };

  const isWide = useIsSize('sm');
  const startExpanded = isWide;

  const [psOpen, psChanged] = useState(startExpanded);
  const [gpsOpen, gpsChanged] = useState(startExpanded);

  const psChecklist = usePremiumserviceChecklist(false);
  const gpsCheclist = GpsTrackerHeadpoints;

  useLayoutEffect(() => {
    psChanged(startExpanded);
    gpsChanged(startExpanded);
  }, [startExpanded]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeUserInput('orderPremiumservice__text', JSON.stringify(psChecklist.items)));
    dispatch(changeUserInput('orderGps__text', JSON.stringify(gpsCheclist.items)));
  }, [psChecklist.items, gpsCheclist.items]);

  const toggleGps = useCallback(
    (e) => {
      gpsChanged(e);
      if (isWide) psChanged(e);
    },
    [isWide]
  );

  const togglePs = useCallback(
    (e) => {
      psChanged(e);
      if (isWide) gpsChanged(e);
    },
    [isWide]
  );

  return (
    <>
      <Grid gutter={'xs'}>
        <Grid.Col
          order={10}
          {...spanOptions}>
          <CustomProductToggle product='premiumservice' />
        </Grid.Col>
        <Grid.Col
          order={20}
          {...spanOptions}>
          {/* <CustomProductToggle product='gpsTracker' /> */}
        </Grid.Col>
        <Grid.Col
          {...spanOptions}
          order={isWide ? 30 : 15}>
          <Checklist
            isOpen={psOpen || (isWide && gpsOpen)}
            onChange={togglePs}
            items={psChecklist.items}
            preview={0}
            labelClosed={undefined}
            labelOpen={undefined}
          />
        </Grid.Col>
        <Grid.Col
          {...spanOptions}
          order={isWide ? 40 : 25}>
          {/* <Checklist
            isOpen={gpsOpen || (isWide && psOpen)}
            onChange={toggleGps}
            items={gpsCheclist.items}
            preview={0}
            labelClosed={undefined}
            labelOpen={undefined}
          /> */}
        </Grid.Col>
      </Grid>
    </>
  );
};

import React, { useCallback, useMemo, useRef } from 'react';
import {
  Text,
  Card,
  Grid,
  createStyles,
  Flex,
  useMantineTheme,
  Space,
  Box,
  Tooltip,
  Input,
  Group,
  TextInput,
} from '@mantine/core';

import { Button } from '@unserkunde/enscompare-components';

import Price2 from '@unserkunde/enscompare-components/src/components/feedback/Price2';
import Eyebrow from '@unserkunde/enscompare-components/src/components/feedback/Eyebrow';

import { FiArrowRight } from 'react-icons/fi';
import { useEyebrowProps, usePriceProps } from '../ensuranceList/EnsuranceItem';
import StickyObserver from '../shared/StickyObserver';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import { selectorIsLoading, useAppDispatch, useAppSelector, useRequiredFieldsLoaded, useUserData } from '@/hooks';
import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { ensuranceSelected, submitAngebotAnfordern } from '@/actions/progress';
import { FaCheckDouble } from 'react-icons/fa';
import ScrollInfoView from '../shared/ScrollInfoView';
import { useResizeObserver } from '@mantine/hooks';
import { useSharedStyles } from '../shared/useSharedStyles';
import { useIsStandaloneMode } from '@/features/AutoloadEns';
import { InnerSizeObserver } from '../shared/InnerSizeObserver';

const headerBorderStyle = (theme: MantineEnsTheme) => '1px solid ' + theme.colors.fgMuted[2];

const cardBaseStyle = (theme: MantineEnsTheme) => ({
  background: 'white',
  borderRadius: '0',
  borderRight: headerBorderStyle(theme),
});

const useStyles = createStyles((theme: MantineEnsTheme, { isSticky }: any) => ({
  headerGroup: {
    background: 'white',
    position: 'sticky',
    top: `-${theme.spacing.xl * 1.8}px`,
    // background: 'cyan',
    zIndex: 100,
    paddingTop: '0 !important',
    ...(!isSticky ? undefined : { filter: 'drop-shadow(0px 7px 5px #00000055)' }),
  },
  cardTop: {
    ...cardBaseStyle(theme),
    overflow: 'visible !important',
    paddingTop: theme.spacing.xl * 1.8 + 'px !important',
    height: '100%',
    borderTop: headerBorderStyle(theme),
    borderBottom: isSticky ? headerBorderStyle(theme) : undefined,
  },
  cardBottom: {
    ...cardBaseStyle(theme),
    height: '100%',
    paddingTop: theme.spacing.md + 'px !important',
    borderBottom: headerBorderStyle(theme),
  },
  firstCard: {
    borderLeft: headerBorderStyle(theme),
  },
  lastCard: {
    borderRight: headerBorderStyle(theme),
  },
  wrap: {
    overflowWrap: 'anywhere',
    flexGrow: 1,
  },
  noOverflow: {
    overflow: 'hidden',
  },
  logo: { maxWidth: '100%', maxHeight: '60px' },
  fg: {
    flexGrow: 1,
  },
}));

const CompareTableHeaderItemTop = ({
  offer,
  isSticky,
  className,
  isSingleItem,
  ensName,
  actionMode,
}: {
  offer: EnsuranceOffer | null;
  ensName: string;
  isSticky: boolean;
  className?: string;
  isSingleItem: boolean;
  actionMode?: 'prev' | 'next' | 'none';
}) => {
  const { classes } = useStyles({ isSticky, isSingleItem });

  const ensBaseInfo = useAppSelector((state) => state.ensCompare.ensuranceBaseInfo);

  const style = useMemo(
    () => ({
      transition: 'opacity 0.15s ease-in-out',
      opacity: isSticky ? 0 : 1,
      curstor: 'initial !important',
    }),
    [isSticky]
  );

  const eyebrowSettingns = useEyebrowProps(offer || ensBaseInfo[ensName], null, style, true);

  const { classes: sharedClasses } = useSharedStyles();

  return (
    <Card className={`${classes.cardTop} ${className}`}>
      {!eyebrowSettingns?.children ? null : (
        <Eyebrow
          {...eyebrowSettingns}
          text={eyebrowSettingns?.children}
        />
      )}

      <Flex
        direction='column'
        align={isSingleItem ? 'center' : undefined}
        h={'100%'}
        gap={0}>
        <Flex justify={'end'}>
          <img
            alt={`Logo of insurance ${offer?.displayName}`}
            src={ensBaseInfo[ensName]?.logo_maybe_vector}
            className={classes.logo}
          />
        </Flex>
        <Space
          h='0'
          mih={'0.5em'}
          className={classes.fg}
        />
        {offer && <PriceDisplay offer={offer} />}
      </Flex>
    </Card>
  );
};

const OrderMailComponent = () => {
  const dispatch = useAppDispatch();

  const submit = useCallback(() => dispatch(submitAngebotAnfordern()), [dispatch]);

  const [email, setEmail, emailHasError] = useUserData('email', null, (e) => e.target.value);

  return (
    <Input.Wrapper
      my='sm'
      label='Angebot per Mail anfordern'
      description='Du möchtest dieses Angebot noch einmal per Mail erhalten? Gib hier deine Adresse ein'>
      <Group>
        <TextInput
          style={{ flexGrow: 1 }}
          placeholder='Deine E-Mail-Adresse'
          size='sm'
          value={email}
          onChange={setEmail}
          error={emailHasError}
        />
        <Button
          onClick={submit}
          variant='subtle'
          compact>
          Senden
        </Button>
      </Group>
    </Input.Wrapper>
  );
};

const CompareTableHeaderItemBottom = ({
  offer,
  isSticky,
  ensName,
  className,
  isSingleItem,
}: {
  offer: EnsuranceOffer | null;
  ensName: string;
  isSticky: boolean;
  className?: string;
  isSingleItem: boolean;
}) => {
  const { classes } = useStyles({ isSticky });

  const dispatch = useAppDispatch();
  const onEnsuranceSelected = useCallback(() => dispatch(ensuranceSelected(offer?.name)), [dispatch, offer?.name]);

  const ensBaseinfoMap = useAppSelector((state) => state.ensCompare.ensuranceBaseInfo);

  return (
    <Card className={`${classes.cardBottom} ${className}`}>
      <Flex
        direction='column'
        gap='xs'
        h='100%'>
        <Text
          className={classes.wrap}
          weight={700}
          ta={isSingleItem ? 'center' : 'right'}>
          {ensBaseinfoMap[ensName]?.displayName}
        </Text>
        <Tooltip
          multiline={true}
          label='Bitte erst die Daten oben ausfüllen'
          position='bottom'
          disabled={!!offer}>
          <Box>
            <InnerSizeObserver
              render={(size, ref) => {
                const isLargeButton = !size || size.width > 110;
                return (
                  <Button
                    ref={ref}
                    disabled={!offer}
                    breakContent={true}
                    fullWidth
                    compact
                    color='secondary'
                    rightIcon={!isLargeButton ? null : <FiArrowRight color='white' />}
                    onClick={onEnsuranceSelected}
                    size='md'>
                    {isLargeButton ? (
                      'Jetzt beantragen'
                    ) : (
                      <EnsIcon
                        color='white'
                        icon='FaShoppingCart'
                      />
                    )}
                  </Button>
                );
              }}
            />
          </Box>
        </Tooltip>
      </Flex>
    </Card>
  );
};

const PriceDisplay = ({ offer }: { offer: EnsuranceOffer }) => {
  const priceProps = usePriceProps(offer);
  const { classes } = useStyles({ isSticky: false });

  return (
    <div className={classes.noOverflow}>
      <Price2 {...priceProps} />
    </div>
  );
};

const CompareTableHeader = ({
  disableDifferences,
  onlyDifferences,
  setOnlyDifferences,
  topMode = false,
  scrollOnLoad = false,
}) => {
  const stateEnsurances = useAppSelector((state) => state.ensuranceList?.list);
  const selectedNames = useAppSelector((state) => state.ensCompare.visibleNames);

  const isStandaloneMode = useIsStandaloneMode();

  const offerByEnsNameMap = useMemo(() => {
    return (stateEnsurances || []).reduce((acc, ens) => ({ ...acc, [ens.name]: ens }), {});
  }, [stateEnsurances]);

  const [isSticky, setIsSticky] = React.useState(false);
  const [isButtonSticky, setIsButtonSticky] = React.useState(false);

  const { classes } = useStyles({ isSticky });

  const gridGutter = 0;

  const theme = useMantineTheme();

  const toggleOnlyDiffs = useCallback(
    () => setOnlyDifferences(!onlyDifferences),
    [onlyDifferences, setOnlyDifferences]
  );

  const ShowDifferencesButton = !disableDifferences && (
    <Button
      w={'100%'}
      compact
      variant={onlyDifferences ? 'filled' : 'outline'}
      onClick={toggleOnlyDiffs}>
      Nur Unterschiede anzeigen
      {onlyDifferences && (
        <>
          <FaCheckDouble
            color='white'
            size={'0.7em'}
          />
        </>
      )}
    </Button>
  );

  const ensurancesLength = selectedNames.length;

  const disableMoveAction = useAppSelector(
    (state) => state.ensCompare.visibleNames.length === state.ensCompare.selectedNames.length
  );

  const [headerRef, headerSize] = useResizeObserver();

  const appReady = useRequiredFieldsLoaded();
  const loading = useAppSelector((state) => selectorIsLoading(state, { includeEnsuranceListLoading: true }));

  return (
    <>
      {/* Vorallem in der Angebotsansicht muss gewartet werden bis alles da ist, da sich sonst noch das Layout weiter verschieben kann */}
      {!loading && appReady && scrollOnLoad && ensurancesLength > 0 && <ScrollInfoView />}
      <StickyObserver
        setIsSticky={setIsSticky}
        offset={theme.spacing.xl * 1.6}
      />
      <div className={classes.headerGroup}>
        <Grid
          ref={headerRef}
          columns={ensurancesLength || 12}
          gutter={gridGutter}>
          {selectedNames.map((ensName, i) => (
            <Grid.Col
              key={i}
              span={1}
              py={0}>
              <CompareTableHeaderItemTop
                offer={offerByEnsNameMap[ensName] || null}
                ensName={ensName}
                isSticky={isSticky}
                key={ensName}
                className={i === 0 ? classes.firstCard : i === ensurancesLength - 1 ? classes.lastCard : undefined}
                isSingleItem={ensurancesLength === 1}
                actionMode={
                  disableMoveAction ? 'none' : i === 0 ? 'prev' : i === ensurancesLength - 1 ? 'next' : 'none'
                }
              />
            </Grid.Col>
          ))}
        </Grid>
        {isButtonSticky && ShowDifferencesButton}
      </div>
      <Grid
        columns={ensurancesLength || 12}
        gutter={gridGutter}>
        {selectedNames.map((ensName, i) => (
          <Grid.Col
            key={i}
            span={1}
            pt={0}>
            <CompareTableHeaderItemBottom
              offer={offerByEnsNameMap[ensName] || null}
              ensName={ensName}
              isSticky={isSticky}
              key={ensName}
              className={i === 0 ? classes.firstCard : i === ensurancesLength - 1 ? classes.lastCard : undefined}
              isSingleItem={ensurancesLength === 1}
            />
          </Grid.Col>
        ))}
      </Grid>

      {!topMode && isStandaloneMode && (
        <Card
          mt='md'
          bg={'primary.0'}>
          <OrderMailComponent />
        </Card>
      )}

      <StickyObserver
        offset={-(headerSize.height / window.devicePixelRatio)}
        setIsSticky={setIsButtonSticky}>
        {ShowDifferencesButton}
      </StickyObserver>
    </>
  );
};

export default CompareTableHeader;

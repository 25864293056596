import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-grid-system';
import ThemedSpan from '../shared/ThemedSpan';
import VoucherBox from './VoucherBox';

const VoucherInfoRow = ({ show, code }) => {
  if (!show) {
    return null;
  }

  return (
    <Row style={{ margin: 0, minHeight: '6em' }}>
      <Col
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'flex-end',
          padding: '.5em 0',
          flexWrap: 'wrap'
        }}>
        <VoucherBox patternLeft>
          <ThemedSpan
            style={{
              color: '#fff',
              padding: '0 20px 0 10px'
            }}>
            Gutschein <b>"{code}"</b> eingelöst{' '}
          </ThemedSpan>
        </VoucherBox>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const unit = state.voucher?.voucher?.amount?.unit;

  return {
    show: state.voucher.valid,
    amount: state.voucher?.voucher?.amount?.value / (unit !== 'percent' ? 100 : 1),
    unit,
    code: state.voucher?.voucher?.code
  };
};

export default connect(mapStateToProps)(VoucherInfoRow);

import { useMantineTheme } from '@mantine/core';

import type { MantineTheme } from '@mantine/core';

import React, { useMemo } from 'react';
import {
  FaDog,
  FaHorse,
  FaCat,
  FaEuroSign,
  FaArrowLeft,
  FaArrowRight,
  FaInfoCircle,
  FaShoppingCart
} from 'react-icons/fa';
import {
  MdOutlineWarningAmber,
  MdAccessibilityNew,
  MdCardTravel,
  MdCheck,
  MdCheckCircleOutline,
  MdDirectionsBike,
  MdElectricBike,
  MdInfoOutline,
  MdOutlineCheck,
  MdOutlineRemoveCircleOutline
} from 'react-icons/md';
import { TbMotorbike } from 'react-icons/tb';
import { FiFile } from 'react-icons/fi';

export const AvailalbeEnsIcons = {
  MdDirectionsBike,
  MdElectricBike,
  MdCardTravel,
  MdAccessibilityNew,
  MdOutlineCheck,
  MdCheckCircleOutline,
  MdCheck,
  MdInfoOutline,
  MdOutlineWarningAmber,
  MdOutlineRemoveCircleOutline,

  FaDog,
  FaCat,
  FaHorse,
  FaShoppingCart,

  TbMotorbike,

  FaEuroSign,
  FaArrowLeft,
  FaArrowRight,
  FaInfoCircle,

  FiFile
};

export const EnsIcon = ({
  icon,
  useAppColor = false,
  appColorShade = null,
  selectColor = null,
  ...rest
}: {
  useAppColor?: boolean;
  appColorShade?: number;
  selectColor?: (theme: MantineTheme) => string;
  icon: keyof typeof AvailalbeEnsIcons;
} & React.ComponentProps<typeof MdDirectionsBike>) => {
  const IconType = useMemo(() => AvailalbeEnsIcons[icon], [icon]);
  const theme = useMantineTheme();

  const color = selectColor
    ? selectColor(theme)
    : useAppColor
    ? theme.colors.primary[appColorShade || (theme.primaryShade as number)]
    : theme.colors.gray[3];

  if (!IconType) return null;

  return (
    <IconType
      color={color}
      {...rest}
    />
  );
};

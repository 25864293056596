import { useAppDispatch, useAppSelector } from '@/hooks';
import { OverlayControls, closeOverlay } from './overlayReducer';
import React, { useCallback } from 'react';
import { useEnsOverviewStyles } from '@/forms/OverviewControls/EnsuranceOverview';
import { Container } from '@mantine/core';
import { ButtonLink } from '@unserkunde/enscompare-components';
import { FaAngleLeft } from 'react-icons/fa';

export const OverlayDisplay = (props) => {
  const controlName = useAppSelector((state) => state.overlay.overlayName);
  const Control = controlName ? OverlayControls[controlName] : null;

  const { classes } = useEnsOverviewStyles();

  const dispatch = useAppDispatch();
  const onGoBack = useCallback(() => dispatch(closeOverlay()), [dispatch]);

  return Control ? (
    <>
      <Container className={classes.topButtonRow}>
        <ButtonLink
          leftIcon={<FaAngleLeft />}
          onClick={onGoBack}>
          Zurück
        </ButtonLink>
      </Container>
      <Container>
        <Control />
      </Container>
    </>
  ) : null;
};

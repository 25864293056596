/* eslint-disable react/prop-types */
import numeral from 'numeral';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Slider, Stack, Switch, createStyles } from '@mantine/core';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setFilterValue } from '../../actions/customFilter/setFilterValue';
import { GetIsDevMode } from '../shared/IsDevMode';
import { getArraySortFunc } from '../../lib/ArraySort';
import { useFilterOptionStyles } from './EnsuranceListOptions';
import { updateValidFilter } from '@/actions/customFilter/updateValidFilter';
import {
  getIsOfferRequestProcess,
  useIsOfferRequestProcess,
} from 'processes/offerRequestProcess/IsOfferRequestProcess';
import { CustomFrontendFilter } from '@/actions/types/CustomFrontendFilter';
import { Infobutton } from 'forms/shared/Common';
import { ENS_NUMERAL_LOCALE, ENS_NUMERAL_LOCALE_FORMATION } from '@/features/NumeralSetup';

const useStyles = createStyles((theme) => ({
  sliderInput: {
    ['label']: {
      fontSize: theme.fontSizes.xs,
    },
  },
  checkbox: {
    cursor: 'pointer',
    ['label']: {
      cursor: 'pointer',
    },
  },
}));

const DynamicFilterLabel = ({ filter }: { filter: CustomFrontendFilter }) => {
  return (
    <>
      {filter.displayName}
      {!filter.tooltip ? null : (
        <>
          {' '}
          <Infobutton text={filter.tooltip} />
        </>
      )}
    </>
  );
};

const DynamicFilterCheckbox = ({ value, onChange, filter }) => {
  value = value || false;

  const changeCallback = useCallback(() => onChange(!value), [onChange, value]);

  const { classes } = useStyles();

  return (
    <Switch
      className={classes.checkbox}
      label={
        <label onClick={changeCallback}>
          <DynamicFilterLabel filter={filter} />
        </label>
      }
      checked={value}
      onChange={changeCallback}
      size='sm'
    />
  );
};

const DynamicFilterSlider = ({ value, onChange, filter, componentProps }) => {
  const { displayFormatter, unit, ...inputProps } = componentProps;
  const [interValue, setInterValue] = useState(value);

  const { classes } = useStyles();

  const calculateUnit = useCallback(
    (valueForDisplay) => {
      if (displayFormatter?.startsWith('numeral:')) {
        numeral.locale(ENS_NUMERAL_LOCALE_FORMATION);
        valueForDisplay = numeral(valueForDisplay).format(displayFormatter.split(':')[1]);
        numeral.locale(ENS_NUMERAL_LOCALE);
      }

      return valueForDisplay + (!unit ? '' : ` ${unit}`);
    },
    [displayFormatter, unit]
  );

  useEffect(() => {
    if (!value) onChange(0);
  }, []);

  useEffect(() => {
    if (value !== null && value !== undefined) setInterValue(value);
  }, [value]);

  const sliderProps = useMemo(() => {
    const { min, max, step, ...rest } = inputProps;

    return {
      min: min ? parseFloat(min) : 0,
      max: max ? parseFloat(max) : 0,
      step: step ? parseFloat(step) : 0,
      ...rest,
    };
  }, [inputProps]);

  return (
    <Input.Wrapper
      size='xs'
      label={filter.displayName}
      className={classes.sliderInput}>
      <Slider
        label={calculateUnit}
        size='sm'
        {...sliderProps}
        value={interValue}
        onChange={setInterValue}
        onChangeEnd={onChange}
      />
    </Input.Wrapper>
  );
};

export const parseTextSetting = (value) => {
  return value
    .split(/\r?\n/)
    ?.map((o) => o.split('='))
    .filter((o) => o.length === 2)
    .reduce((obj, p) => {
      obj[p[0]] = p[1];
      return obj;
    }, {});
};

const DynamicFilter = ({ filter, skipReload = false, onFilterChanged = null }) => {
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) => state.customFilter.filterValues[filter.internalName]);
  const onChange = useCallback(
    async (value, customSkipReload = false) => {
      await dispatch(setFilterValue(filter.internalName, value, customSkipReload || skipReload));
      await dispatch(updateValidFilter());
      if (onFilterChanged) onFilterChanged();
    },
    [onFilterChanged, filter?.internalName, skipReload]
  );

  const componentProps = useMemo(() => {
    const props = parseTextSetting(filter.componentProps);
    if (props.hideInOfferRequestProcess) delete props.hideInOfferRequestProcess;
    return props;
  }, [filter.componentProps]);

  let Control: React.ElementType = DynamicFilterCheckbox;
  if (filter.type === 'slider') {
    Control = DynamicFilterSlider;
  }

  const component = (
    <Control
      filter={filter}
      value={value}
      onChange={onChange}
      componentProps={componentProps}
    />
  );

  if (filter.hidden && filter.hidden !== '0') {
    return !GetIsDevMode() ? null : (
      <div style={{ pointerEvents: 'none', userSelect: 'none', opacity: '0.3' }}>{component}</div>
    );
  }

  return component;
};

export const DynamicFilterList = (props) => {
  const { showGroupNames = false } = props;

  const { classes } = useFilterOptionStyles(null);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateValidFilter());
  }, []);

  const filters = useAppSelector((state) => state.customFilter.activeFilter);

  const isOfferRequestProcess = useIsOfferRequestProcess();

  const filterChunks: any[][] = useMemo(() => {
    const ordered = filters
      .filter((o) => o.hidden !== '1' || GetIsDevMode())
      .filter((o) => {
        const componentProps = parseTextSetting(o.componentProps);
        return !componentProps?.hideInOfferRequestProcess || !isOfferRequestProcess;
      })
      .sort(getArraySortFunc(['group', 'hidden']));

    const chunkSize = 4;
    const chunks: any[] = [[]];
    let lastGroupName = '';

    ordered.forEach((item) => {
      const lastChunckIsFull = chunks[chunks.length - 1].length == chunkSize;
      const useNewGroup = lastGroupName !== item.group;
      if (lastChunckIsFull || useNewGroup) chunks.push([]);
      chunks[chunks.length - 1].push(item);
      lastGroupName = item.group;
    });

    return chunks.filter((o) => o.length > 0);
  }, [filters, isOfferRequestProcess]);

  if (!filters || filters.length === 0) return null;

  const shownGroupNames: any[] = [];
  return (
    <>
      {filterChunks.map((filterChunk, i) => (
        <div
          className={classes.listOptionSegment}
          key={i}
          //group={filterChunk[0].group}
        >
          <label className={classes.listOptionSegmentLabel}>
            {showGroupNames ? (
              <span>
                {shownGroupNames.includes(filterChunk[0].group) ? <></> : filterChunk[0].group}
                {shownGroupNames.push(filterChunk[0].group) && false}
              </span>
            ) : (
              <>{i > 0 ? <></> : <span>Diese Punkte sind mir wichtig:</span>}</>
            )}
          </label>

          <Stack spacing='sm'>
            {filterChunk.map((filter) => (
              <DynamicFilter
                {...props}
                key={filter.internalName}
                filter={filter}
              />
            ))}
          </Stack>
        </div>
      ))}
    </>
  );
};

import { createEnsProcessDefinition, EnsProcessDefinitionDefault } from '@/processes/processDefinition/EnsProcess';

import PersonalInput from '@/forms/checkout/inputs/PersonalInput';
import PaymentInput from '@/forms/checkout/inputs/PaymentInput';
import BikeInput from '@/forms/checkout/inputs/BikeInput';
import Zusammenfassung from '@/forms/zusammenfassung/Zusammenfassung';
import { backToEnsList, submitZusammenfassung } from '@/actions/progress';
import {
  validateBike,
  validatePayment,
  validatePersonal,
  validatePersonalContact,
  validateZusammenfassung,
} from '@/actions/validation/validation';
import EnsuranceOverview from '@/forms/OverviewControls/EnsuranceOverview';
import AddSecondBike from '@/forms/OverviewControls/AddSecondBike';
import PersonalContactInput from '@/forms/checkout/inputs/PersonContactInput';
import { uncompletedOrderSend } from '@/actions/data/uncompletedOrderSend';
import InstanceNaming from '@/forms/shared/InstanceNaming';
import React from 'react';
import { onSaveUncompletedOrderConsent } from '@/reducer/uncompletedOrder';

export const FluentProgress: EnsProcessDefinitionDefault = createEnsProcessDefinition({
  processSteps: {
    '': {
      component: EnsuranceOverview,
      validate: () => [],
      title: 'Eingangsfragen',
      useFluentProgress: false,
      stepperTitle: '',
      hideDefaultButtons: true,
    },
    secondBike: {
      component: AddSecondBike,
      validate: () => [],
      title: 'Weitere Angaben',
      useFluentProgress: false,
      stepperTitle: '',
      hideDefaultButtons: true,
      stepIsVisible: ({ state }) => Object.keys(state.userData.bikes).length > 1,
    },
    ensuranceList: {
      validate: () => [],
      stepperTitle: '',
      title: 'Angebote',
      component: undefined,
      useFluentProgress: true,
    },
    personalContactInfo: {
      validate: validatePersonalContact,
      stepperTitle: 'Antrag',
      title: 'Persönliche Informationen',
      component: PersonalContactInput,
      useFluentProgress: true,
      previosStep: 'ensuranceList',
      prevStepAction: (dispatch) => dispatch(backToEnsList()),
      nextStepAction: async (dispatch, next) => {
        const dispatchResult = await next(dispatch);
        if (dispatchResult) {
          await dispatch(onSaveUncompletedOrderConsent());
        }
      },
    },
    personalInfo: {
      validate: validatePersonal,
      stepperTitle: 'Antrag',
      title: 'Adressdaten',
      component: PersonalInput,
      useFluentProgress: true,
    },
    paymentInfo: {
      validate: validatePayment,
      stepperTitle: 'Antrag',
      title: 'Zahlungsdaten',
      component: PaymentInput,
      useFluentProgress: true,
    },
    bikeinfo: {
      validate: validateBike,
      stepperTitle: 'Antrag',
      title: (
        <>
          <InstanceNaming fieldName={'ensuranceObjectName'} /> Informationen
        </>
      ),
      component: BikeInput,
      useFluentProgress: true,
    },
    zusammenfassung: {
      validate: validateZusammenfassung,
      stepperTitle: 'Zusammenfassung',
      title: 'Zusammenfassung',
      component: Zusammenfassung,
      useFluentProgress: true,
      fluentPreventBack: true,
      nextStepLabel: 'Zahlungspflichtig bestellen',
      nextStepAction: (dispatch) => dispatch(submitZusammenfassung()),
    },
  },
});

import { useAppDispatch, useAppSelector } from '@/hooks';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

import { MD5 } from 'object-hash';
import { uncompletedOrderSend } from '@/actions/data/uncompletedOrderSend';

const ActiveSaver = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.userData);
  const [userDataDebounced] = useDebouncedValue(userData, 3000);
  const [lastState, setLastState] = useState<string | null>(null);

  useEffect(() => {
    const hash = MD5(userDataDebounced);
    if (lastState === hash) {
      return;
    }

    dispatch(uncompletedOrderSend());
    setLastState(hash);
  }, [userDataDebounced]);

  return null;
};

export const UncompletedOrderSaver = () => {
  const consent = useAppSelector((state) => state.uncompletedOrder.consent);
  if (!consent) return null;
  return <ActiveSaver />;
};

type EnsVoucher = {
  valid: boolean;
  ensurance: string;
  type: 'cashback';
  amount: {
    value: string;
    unit: 'cent' | 'percent';
    limit: any;
  };
};

const defaultState = { voucher: null as EnsVoucher | null, loading: false, valid: false };

export default function voucher(state = defaultState, action): typeof defaultState {
  if (action.type === 'ENS_VOUCHER_LOADING') {
    return { ...state, loading: true, valid: false };
  } else if (action.type === 'ENS_VOUCHER_LOADED') {
    return { ...state, valid: action.valid, voucher: action.voucher, loading: false };
  } else if (action.type === 'ENS_VOUCHER_RESET') {
    return { ...state, valid: false, voucher: null, loading: false };
  }

  return state;
}

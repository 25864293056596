import { formatPostValue } from '../post';
import { getUseComparePreview } from '../shared/getUseComparePreview';
import { getPageMode, getRefererId } from '../data';
import { getEnsFilter } from '../shared/getEnsFilter';
import { GetIsDevMode } from '../../forms/shared/IsDevMode';
import { getQueryStrings } from '../shared/QueryParams';
import { DynamicEntryQuestionType, EntryQuestPositionType } from '../types/DynamicEntryQuestionType';
import { getClientId } from './audit';
import FormularEvaluate, { GetEvaluationContext, isSchnellrechner } from '@/lib/FormularEvaluate';
import { BikeType, UserDataType } from '@/reducer/userData';
import { getCountryCode } from '@/actions/shared/getCountryCode';
import { getIsOfferRequestProcess } from 'processes/offerRequestProcess/IsOfferRequestProcess';
import { getAutoloadEnsurances, getIsAutloadStandaloneMode } from '@/features/AutoloadEns';

import Cookie from 'js-cookie';

declare let window: any;

export const addCustomFilter = (state: RootState, formData: FormData) => {
  const filterValues = {};

  state.customFilter.activeFilter.forEach(
    (filter) => (filterValues[filter.internalName] = state.customFilter.filterValues[filter.internalName] || false)
  );

  if (state.options.options.includes('hideEnsResultFilterBox')) {
    formData.append('customFilters', JSON.stringify({}));
    return;
  }

  formData.append('customFilters', JSON.stringify(filterValues));
};

const nameof = <T>(name: keyof T) => name;

const skippedNonBikeObjectFields: string[] = [
  nameof<BikeType>('bikeTechnischEinwandfrei'),
  nameof<BikeType>('rahmennummenNachreichen'),
  nameof<BikeType>('manguaranteemonths'),
  nameof<BikeType>('ageInYears'),
  nameof<BikeType>('newBuyed'),
  nameof<BikeType>('lockPrice'),
];
const skippedNonBikeRequestFields: string[] = [
  nameof<UserDataType>('sofortschutz'),
  nameof<UserDataType>('manguaranteemonths'),
];

const add_ad_ids = (form: FormData, state: RootState) => {
  const currentDomainName = window.location.hostname;

  if (
    !currentDomainName.includes('unserkunde.de') &&
    !currentDomainName.includes('fahrsicherung.de') &&
    !currentDomainName.includes('ebikeversicherungen.net') &&
    !currentDomainName.includes('dogvers.de') &&
    !currentDomainName.includes('host.docker.internal') &&
    !currentDomainName.includes('kv.unserkunde.de') &&
    !currentDomainName.includes('localhost')
  ) {
    return;
  }

  const gclid: string = state.userData?.gclid || getQueryStrings().gclid || Cookie.get('_gcl_aw'); //Adsense
  const uetmsclkid: string = state.userData?.uetmsclkid ||getQueryStrings().msclkid || Cookie.get('_uetmsclkid'); // Bing (Microsoft ads)
  const fbclid: string = state.userData?.fbclid ||getQueryStrings().fbclid || Cookie.get('_fbc'); // Facebook

  if (gclid) {
    form.append('gclid', gclid);
  }

  if (uetmsclkid) {
    form.append('uetmsclkid', uetmsclkid);
  }

  if (fbclid) {
    form.append('fbclid', fbclid);
  }
};

export const addUserDataPostFields = (state: RootState, userDataRequest: UserDataType, formData: FormData) => {
  const usedDeviceMode = state.userData.deviceModeOverride || state.devicemode.devkey;

  // Set correct devicemode
  Object.keys(userDataRequest.bikes).forEach((bikeKey) => {
    userDataRequest.bikes[bikeKey].devicemode = userDataRequest.bikes[bikeKey].devicemode || usedDeviceMode;
    // Remove Non visible fields
    if (parseInt(userDataRequest.bikes[bikeKey].newBuyed)) {
      delete userDataRequest.bikes[bikeKey].firstBuyDate;
    }
  });

  Object.keys(userDataRequest).forEach((key) => {
    const value = typeof userDataRequest[key] === 'object' ? { ...userDataRequest[key] } : userDataRequest[key];

    //cleanup non bike fields
    if (state.ensfields.ens_instance_mode !== 'bike') {
      if (key === 'bikes') {
        Object.keys(value).forEach((bikeKey) => {
          skippedNonBikeObjectFields.forEach((fieldName) => {
            delete value[bikeKey][fieldName];
          });
        });
      } else if (skippedNonBikeRequestFields.includes(key)) {
        return; //continue
      }
    }

    formData.append(key, formatPostValue(userDataRequest[key]));
  });

  formData.append('useComparePreview', getUseComparePreview());
  formData.append('pageMode', getPageMode());
  formData.append('presetId', state.options.presetId || '');
  formData.append('devicemode', usedDeviceMode || '');
  formData.append('devmode', GetIsDevMode() ? 'true' : 'false');

  formData.append('insuranceObjectsField', 'bikes');
  formData.append('refererId', getRefererId() || '');
  formData.append('options', (state.options.options || []).join(','));
  formData.append('ensFilter', getEnsFilter(state));
  formData.append('isGewerblicherAntragsprozess', (usedDeviceMode === 'gewerblich').toString());
  formData.append('isSchnellrechner', isSchnellrechner(state).toString());
  formData.append('isOfferRequestProcess', getIsOfferRequestProcess(state) ? 'true' : 'false');
  formData.append('isCompareStandaloneMode', getAutoloadEnsurances(state) === 'compare_standalone' ? 'true' : 'false');
  formData.append('ensCalcLocation', window.ensOriginalCalcLocation || window.location.href);
  formData.append('ensClientId', getClientId());

  add_ad_ids(formData, state);

  let customProductSelection = JSON.parse(JSON.stringify(state.customProducs.productSelection));
  Object.keys(customProductSelection).forEach((key) => {
    if (customProductSelection[key].amount === 0) {
      delete customProductSelection[key];
    }
  });
  formData.append('customProducts', JSON.stringify(customProductSelection));

  if (state.ensfields.ens_instance_mode === 'animal' && getIsAutloadStandaloneMode(state))
    formData.append('skipInsuranceTypeCheck', 'true');

  if (getCountryCode(state)) formData.append('countryCode', getCountryCode(state) as string);
  if (getQueryStrings()?.disableCookieReferer) formData.append('disableCookieReferer', 'true');
};

import IBAN from 'iban';
import { differenceInYears } from 'date-fns';
import { validate as validateEmail } from 'email-validator';

import { getAntragsaenderungState } from '@/features/Antragsaenderung';
import { DynamicEntryQuestionType, EntryQuestPositionType } from '@/actions/types/DynamicEntryQuestionType';
import { validateBikeDynamic, validateDynamic } from '@/actions/validation/lib/DynamicFieldValidation';

import { getCountryCode } from '@/actions/shared/getCountryCode';

import './ValidatorJsSetup';

const regex_plz = /^\d{5}$/;
const regex_plz_AT = /^\d{4}$/;

export function asBool(value: any) {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'true' || value === '1';
  }
  if (typeof value === 'number') {
    return value === 1;
  }
  return false;
}

function addToError(errors, inputField, field, condition) {
  if (!inputField) {
    inputField = field;
  }

  if (field !== inputField) {
    return errors;
  }
  if (condition) {
    return [...errors.filter((o) => o !== field), field];
  }
  return errors.filter((o) => o !== field);
}

function isNotEmpty(errors, userData, inputField, field) {
  return addToError(errors, inputField, field, !userData[field] || !userData[field].trim().length);
}

export function isTrue(errors, userData, inputField, field) {
  return addToError(errors, inputField, field, userData[field] !== true);
}

export function custom(errors, fieldName, condition) {
  return addToError(errors, fieldName, fieldName, condition);
}

export const validateSingle = (err, fieldName, condition) => {
  return addToError(err, fieldName, fieldName, condition);
};

export const getValidateOverviewInput =
  (bikeIdFilter: string[] | undefined = null) =>
  (_, userData, __ = [], state: RootState) => {
    return mergeValidationResult(
      validateDynamic(userData, 'entryQuestionsTop', state),
      validateDynamic(userData, 'entryQuestions', state),
      validateDynamic(userData, 'entryQuestionsMore', state),
      ...Object.keys(userData.bikes)
        .filter((bi) => !bikeIdFilter || bikeIdFilter.includes(bi))
        .map((bikeId) => validateBikeDynamic(userData, 'objectEntryQuestions', state, bikeId)),
      ...Object.keys(userData.bikes)
        .filter((bi) => !bikeIdFilter || bikeIdFilter.includes(bi))
        .map((bikeId) => validateBikeDynamic(userData, 'objectEntryQuestionsTop', state, bikeId))
    );
  };

export const validateBike = (fieldName, userData: UserDataType, options: any[] = [], state: RootState) => {
  const dynamicResult = mergeValidationResult(
    ...Object.keys(userData.bikes).map((bikeId) => validateBikeDynamic(userData, 'objectQuestions', state, bikeId))
  );

  const errs = { errors: [], bikeErrors: {} };

  if (state.ensfields.ens_instance_mode === 'bike') {
    const bikeKeys = Object.keys(userData.bikes);

    bikeKeys.forEach((bikeId) => {
      let err = [];
      const bikeUserData = userData.bikes[bikeId];

      err = isNotEmpty(err, bikeUserData, fieldName, 'bikeMarke');
      err = isNotEmpty(err, bikeUserData, fieldName, 'bikeTypeName');

      err = custom(
        err,
        'rahmennummer',
        !bikeUserData.rahmennummenNachreichen &&
          !bikeUserData.rahmennummer &&
          !options.includes('rahmennummer_optional')
      );

      err = custom(
        err,
        'buyDate',
        (!bikeUserData.buyDate && options.includes('kaufdatumInBikeinfo')) ||
          //@ts-ignore
          ((new Date() - new Date(bikeUserData.buyDate)) / (1000 * 60 * 60 * 24 * 365.25) > 3 &&
            options.includes('buy_date_years_3'))
      );

      err = custom(
        err,
        'lockproducerSelect',
        options.includes('lockproducerSelect') && !bikeUserData.lockproducerSelect
      );

      err = custom(err, 'lockproducer', options.includes('lockinfo') && !bikeUserData.lockproducer);
      err = custom(err, 'lockname', options.includes('lockinfo') && !bikeUserData.lockname);
      err = custom(err, 'locktype', options.includes('lockinfo') && !bikeUserData.locktype);

      err = custom(err, 'schlossnummer', options.includes('schlossnummer') && !bikeUserData.schlossnummer);
      err = custom(err, 'akkunummer', options.includes('akkunummer') && !bikeUserData.akkunummer);
      err = custom(err, 'rechnugnsnummer', options.includes('rechnugnsnummer') && !bikeUserData.rechnugnsnummer);
      err = custom(
        err,
        'manguaranteemonths',
        options.includes('manguaranteemonths') && !bikeUserData.manguaranteemonths
      );

      errs.bikeErrors[bikeId] = err;
    });
  }

  return mergeValidationResult(dynamicResult, errs);
};

export const validatePersonal = (fieldName, userData, options: any[] = [], state: RootState) => {
  let err = [];

  err = isNotEmpty(err, userData, fieldName, 'street');
  err = isNotEmpty(err, userData, fieldName, 'street_number');
  err = isNotEmpty(err, userData, fieldName, 'city');

  if (options.includes('requirePhone')) {
    err = isNotEmpty(err, userData, fieldName, 'phone');
  }

  const failPhoneIsRequired =
    options.includes('requirePhone') &&
    (!userData.phone || (userData.phone as string).toString().replaceAll('0', '').length === 0);

  err = validateSingle(err, 'phone', failPhoneIsRequired || (userData.phone && userData.phone.length > 20));

  err = validateSingle(
    err,
    'plz',
    !userData.plz || !(getCountryCode(state) === 'AT' ? regex_plz_AT : regex_plz).test(userData.plz)
  );

  return err;
};

export const validatePersonalContact = (fieldName, userData, options: any[] = [], state: RootState) => {
  let err = [];

  err = isNotEmpty(err, userData, fieldName, 'forename');
  err = isNotEmpty(err, userData, fieldName, 'lastname');
  err = isNotEmpty(err, userData, fieldName, 'anrede');
  err = validateSingle(err, 'firma', userData.isFirma && !userData.firma);
  err = validateSingle(err, 'email', !userData.email || !validateEmail(userData.email));

  err = validateSingle(
    err,
    'birthday',
    !userData.isFirma && (!userData.birthday || differenceInYears(new Date(), new Date(userData.birthday)) < 18)
  );

  return err;
};

export const validateAngebotAnfordern = (fieldName, userData) => {
  let err = [];

  err = validateSingle(err, 'email', !userData.email || !validateEmail(userData.email));

  return err;
};

export const validatePreisanfrage = (_, userData, __: any[] = [], state: RootState) => {
  let err = [];
  err = validateSingle(err, 'email', !userData.email || !validateEmail(userData.email));

  const requestFieldsSelector = (question: DynamicEntryQuestionType) => question.position === 'priceRequestForm';

  return mergeValidationResult(
    { errors: err, bikeErrors: [] },
    validateDynamic(userData, requestFieldsSelector, state)
  );
};

export const validateOfferRequestProcess = (fieldName, userData, _ = [], state: RootState) => {
  let err = [];

  err = isNotEmpty(err, userData, fieldName, 'email');

  const offerRequestEntryFieldSelector = (positionType: EntryQuestPositionType) => (e: DynamicEntryQuestionType) => {
    return (
      e.positionType === positionType &&
      (Array.isArray(e.displayPositions) ? e.displayPositions : e.displayPositions.split(',')).includes(
        'offerrequestprocess'
      )
    );
  };

  return mergeValidationResult(
    { errors: err, bikeErrors: [] },
    validateDynamic(userData, offerRequestEntryFieldSelector('request'), state),
    ...Object.keys(userData.bikes).map((bikeId) =>
      validateBikeDynamic(userData, offerRequestEntryFieldSelector('object'), state, bikeId)
    )
  );
};

export const validateZusammenfassung = (fieldName, userData, _ = []) => {
  let err = [];

  // err = isTrue(err, userData, fieldName, "rechtsbelehrung");

  if (getAntragsaenderungState() === 'ammerlaender') {
    err = isNotEmpty(err, userData, fieldName, 'versicherungsnummer');
  }

  return err;
};

export const validatePayment = (fieldName, userData, _: any[] = [], state) => {
  let err = [];

  if (userData.paymentMethod === 'iban') {
    err = custom(err, 'iban', !IBAN.isValid(userData.iban));
  }

  err = custom(err, '_loading', state.checkout.loading);
  err = custom(err, '_offer_failed', state.checkout.offer.failed);

  err = isNotEmpty(err, userData, fieldName, 'paymentPeriod');

  err = custom(
    err,
    'ensuranceStartDate',
    !userData.ensuranceStartDate || new Date(userData.ensuranceStartDate) < new Date()
  );

  return mergeValidationResult(
    { errors: err, bikeErrors: [] },
    validateDynamic(userData, 'payment', state),
    validateDynamic(userData, 'paymentTop', state)
  );

  return err;
};

export const hasError = (err) => {
  if (Array.isArray(err)) {
    return err.length > 0;
  }

  const bikeErrors = err.bikeErrors || {};
  return err.errors.length > 0 || Object.keys(bikeErrors).some((o) => bikeErrors[o] && bikeErrors[o].length > 0);
};

export const getValidationResult = (res) => {
  if (Array.isArray(res)) {
    return { errors: res, bikeErrors: [] };
  }

  return res;
};

export const mergeValidationResult = (...results) =>
  results.reduce((a, b) => {
    const aRef = getValidationResult(a);
    const bRef = getValidationResult(b);

    const res = {
      errors: [...aRef.errors, ...bRef.errors],
      bikeErrors: [],
    };

    [...Object.keys(aRef.bikeErrors), ...Object.keys(bRef.bikeErrors)]
      .filter((v, i, a) => a.indexOf(v) === i)
      .forEach((bikeId) => {
        res.bikeErrors[bikeId] = [...(aRef.bikeErrors[bikeId] || []), ...(bRef.bikeErrors[bikeId] || [])];
      });

    return res;
  }, []);

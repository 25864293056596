import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EnsPriceInfo } from './EnsPriceInfo';
import { useMemo } from 'react';
import { StripeProductInfo } from '@/reducer/stripeProducts';
import { Anchor, Box, Divider, Group, LoadingOverlay, Stack, Table, Text } from '@mantine/core';
import { Price2 } from '@unserkunde/enscompare-components';
import numeral from 'numeral';
import { frequentDisplayName } from '../Common';
import { changeUserInput } from '@/actions/form';
import OfferPriceHints, { EnsuranceInfoButton } from '@/forms/ensuranceList/OfferPriceHints';
import { ServerCustomProductSelection, setProductState, useCustomProduct } from '@/reducer/customProducts';
import requestEnsurance from '@/actions/data/requestEnsurance';
import { CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { OverlayNames, openOverlay } from '@/processes/overlay/overlayReducer';
import { HiCursorClick } from 'react-icons/hi';

const PriceGroup = ({ children }) => {
  return (
    <Group
      position='apart'
      align='baseline'>
      {children}
    </Group>
  );
};

export const StripeProductRow = (props: { product: StripeProductInfo }) => {
  const priceInfo = useMemo(() => EnsPriceInfo.fromStripePriceInfo(props.product), [props.product]);

  const dispatch = useAppDispatch();

  const onDeleteProduct = useCallback(async () => {
    if (props.product.meta?.customProductName) {
      await dispatch(setProductState(props.product.meta?.customProductName, { variant: 'none' }));
      await dispatch(requestEnsurance());
    } else dispatch(changeUserInput('orderPremiumservice', false));
  }, [props.product]);

  return (
    <PriceGroup>
      <Text mr={'lg'}>{props.product.product.name}</Text>

      <Stack
        spacing={0}
        align='flex-end'>
        <Price2.Price label={priceInfo.getPeriodDisplay()}>{numeral(priceInfo.amount).format('0.00')} €</Price2.Price>
        <Text
          onClick={onDeleteProduct}
          size={'xs'}
          color='gray'
          style={{ cursor: 'pointer' }}>
          Entfernen
        </Text>
      </Stack>
    </PriceGroup>
  );
};

export const CustomProductPriceRow = <T extends CustomProductNames>(props: {
  product: CustomProductNames;
  productInfo: ServerCustomProductSelection<T>;
}) => {
  const priceInfos = useMemo(
    () => props.productInfo?.prices?.map((p) => EnsPriceInfo.fromCustomProductPrice(p)),
    [props.productInfo]
  );

  const dispatch = useAppDispatch();

  const onAddRemove = useCallback(async () => {
    if (props.productInfo.isNotIncluded) {
      // Add Product
      if (!props.productInfo.itemsToAdd)
        await dispatch(setProductState(props.product, { variant: 'default', amount: 1 }));
      else
        await Promise.all(
          Object.values(props.productInfo.itemsToAdd).map((item) =>
            dispatch(setProductState(item.productName, { variant: item.variant as 'default', amount: 1 }))
          )
        );
    } else {
      // Remove Product
      if (!props.productInfo.itemsToRemove) {
        await dispatch(setProductState(props.product, { variant: 'none' }));
      } else {
        await Promise.all(
          props.productInfo.itemsToRemove.map(async (item) =>
            dispatch(setProductState(item.productName, { variant: 'none' }))
          )
        );
      }
    }
    await dispatch(requestEnsurance());
  }, [props.productInfo]);

  const detailsOverlay: OverlayNames | null = useMemo(() => {
    if (props.product === 'gpsTracker') return 'gpsTracker';
    if (props.product === 'premiumservice') return 'premiumservice';
    return null;
  }, [props.product]);

  const TextContainer = useMemo(() => {
    if (!detailsOverlay) return ({ children }) => children;

    return ({ children }) => (
      <Anchor
        color='blue'
        onClick={() => dispatch(openOverlay(detailsOverlay))}>
        {children}
        <Box
          component={HiCursorClick}
          color='gray'
          mx={2}
        />
      </Anchor>
    );
  }, [detailsOverlay]);

  if (!priceInfos || priceInfos.length === 0) return null;

  return priceInfos.map((pi, i) => (
    <PriceGroup key={i}>
      <Group
        spacing={0}
        mr={'lg'}>
        <TextContainer>
          <Text
            style={{ display: 'inline-block' }}
            td={props.productInfo.isNotIncluded && 'line-through'}
            color={!detailsOverlay && props.productInfo.isNotIncluded && 'gray'}>
            {props.productInfo.displayName}
          </Text>
        </TextContainer>
        {props.productInfo.isNotIncluded && (
          <Text
            color='gray'
            fz='0.8em'>
            &nbsp;(nicht gewählt)
          </Text>
        )}
      </Group>

      <Stack
        spacing={0}
        align='flex-end'>
        {props.productInfo.isNotIncluded ? (
          <>
            {' '}
            <Price2.Price
              label={'Jetzt hinzufügen für ' + pi.displayFormat() + '€'}
              children={null}
              style={{ cursor: 'pointer' }}
              onClick={onAddRemove}
            />
          </>
        ) : (
          <>
            <Price2.Price label={pi.getPeriodDisplay()}>{numeral(pi.amount).format('0.00')} €</Price2.Price>
            <Text
              onClick={onAddRemove}
              size={'xs'}
              color='gray'
              style={{ cursor: 'pointer' }}>
              Entfernen
            </Text>
          </>
        )}
      </Stack>
    </PriceGroup>
  ));
};

export const EnsProductRow = () => {
  const offer = useAppSelector((state) => state.checkout.offer);

  return (
    <PriceGroup>
      <Text mr={'lg'}>Versicherung</Text>

      <Price2.Price
        label={
          <React.Fragment>
            {frequentDisplayName(offer?.period)}
            <EnsuranceInfoButton offer={offer} />
          </React.Fragment>
        }>
        {numeral(offer.offer).format('0.00')} €
      </Price2.Price>
    </PriceGroup>
  );
};

const CashbackDisplay = () => {
  const offer = useAppSelector((state) => state.checkout.offer);

  if (!offer.voucherTotalEuroCent) return null;

  return (
    <PriceGroup>
      <Text mr={'lg'}>Gutschein</Text>

      <Price2.Price label={frequentDisplayName('once')}>
        {numeral(-offer.voucherTotalEuroCent).format('0.00')} €
      </Price2.Price>
    </PriceGroup>
  );
};

export const SumDisplay = () => {
  const offer = useAppSelector((state) => state.checkout.offer);
  const customProductPrices = useAppSelector((state) => state.customProducs.serverProductSelection) || {};

  const offerInfoFirstYear = useMemo(
    () =>
      !offer.period
        ? EnsPriceInfo.empty()
        : new EnsPriceInfo(
            offer.prices_firstYear?.brutto?.reduce((a, b) => a + b, 0) || 0,
            EnsPriceInfo.periodFromOffer(offer),
            'unknown'
          ),
    [offer]
  );

  const offerInfo = useMemo(
    () =>
      !offer.period
        ? EnsPriceInfo.empty()
        : new EnsPriceInfo(
            offer.prices?.brutto?.reduce((a, b) => a + b, 0) || 0,
            EnsPriceInfo.periodFromOffer(offer),
            'unknown'
          ),
    [offer]
  );

  const productInfos = useMemo(() => {
    return [
      ...Object.keys(customProductPrices).map((k) =>
        customProductPrices[k].isNotIncluded
          ? null
          : customProductPrices[k].prices?.map((p) => EnsPriceInfo.fromCustomProductPrice(p))
      ),
    ]
      .flat()
      .filter((o) => o);
  }, [customProductPrices]);

  const sumFirstYear = useMemo(() => {
    return [...productInfos, offerInfoFirstYear].reduce(
      (acc: EnsPriceInfo, pi: EnsPriceInfo) => (acc ? acc.add(pi) : null),
      new EnsPriceInfo(0, 'once', 'unknown')
    );
  }, [offerInfoFirstYear, productInfos]);

  const sumAfterward = useMemo(() => {
    return [...productInfos, offerInfo].reduce(
      (acc: EnsPriceInfo, pi: EnsPriceInfo) => (acc ? acc.add(pi) : null),
      new EnsPriceInfo(0, 'once', 'unknown')
    );
  }, [productInfos, offerInfo]);

  if (sumFirstYear === null || sumAfterward === null) return null;

  const hasDifferentFirstYear =
    offer.prices_firstYear && sumFirstYear && sumFirstYear.amount > 0 && sumFirstYear.amount !== sumAfterward.amount;

  return (
    <Stack>
      <Divider />

      {hasDifferentFirstYear && (
        <PriceGroup>
          <Text mr={'lg'}>Summe 1. Jahr</Text>

          <Price2.Price label={sumFirstYear.getPeriodDisplay()}>
            {numeral(sumFirstYear.amount).format('0.00')} €
          </Price2.Price>
        </PriceGroup>
      )}

      <PriceGroup>
        <Text mr={'lg'}>{hasDifferentFirstYear ? 'Danach' : 'Gesamt'}</Text>

        <Price2.Price label={sumAfterward.getPeriodDisplay()}>
          {numeral(sumAfterward.amount).format('0.00')} €
        </Price2.Price>
      </PriceGroup>
    </Stack>
  );
};

export const TabledPriceDisplay = () => {
  const serverCustomProducts = useAppSelector((state) => state.customProducs.serverProductSelection) || {};

  const loading = useAppSelector((state) => state.checkout.loading);

  return (
    <Stack
      ml={'auto'}
      style={{ position: 'relative' }}>
      {/* {stripePrices.map((pi) => (
        <StripeProductRow product={pi} />
      ))} */}

      {Object.keys(serverCustomProducts).map((sk) => (
        <CustomProductPriceRow
          key={sk}
          product={sk as CustomProductNames}
          productInfo={serverCustomProducts[sk]}
        />
      ))}

      <EnsProductRow />
      <CashbackDisplay />

      <SumDisplay />

      <LoadingOverlay visible={loading} />
    </Stack>
  );
};

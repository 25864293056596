import { changeUserInput } from '@/actions/form';
import { useAppDispatch, useAppSelector, useOnAppReady } from '@/hooks';
import { setProductState } from '@/reducer/customProducts';

export const DefaultCustomProducts = () => {
  // @ts-ignore
  const customProductsLoaded = useAppSelector((state) => state.userData.__customProductsDefaultLoaded);

  const dispatch = useAppDispatch();

  useOnAppReady(() => {
    if (customProductsLoaded) return;

    dispatch(setProductState('premiumservice', { amount: 1, variant: 'default' }));
    // @ts-ignore
    dispatch(changeUserInput('__customProductsDefaultLoaded', true));
  });

  return null;
};

import { getUrl } from '../data';
import requestOffers, { applyDefaultUserData } from '../data/requestOffers';
import { selectDevMode } from '../devmode';
import Audit from '../data/audit';
import { getIsOfferRequestProcess } from '@/processes/offerRequestProcess/IsOfferRequestProcess';
import { getUserInputEventDebugInfo } from '../form';

const hepsterSettings = {
  options: ['nolockprice', 'no_option_entry_info', 'no_priUse_quesion', 'no_kaufdatum'],
  filterkey: 'hepster,ammerlaender',
};

const schnellrechnerSettings = {
  ...hepsterSettings,
  options: [
    'no_option_entry_info_except_vertragslaufzeit',
    'entry_option_older_than12Months',
    ...hepsterSettings.options,
  ],
  filterkey: null,
};

const ammlntvSettings = {
  options: ['no_priUse_quesion', 'nolockprice', 'no_diebstahl_question', 'no_option_entry_kaufdatum'],
  filterkey: 'ammerlaender',
  settings: {
    logoHref: getUrl('/wp-admin/admin-post.php?action=logoByEnsName&ens=Ammerlaender'),
    stepperColor: '#009757',
    primaryColor: '#009757',
    ensButtonClass: 'ens_button_ammerlaender',
  },
};

const ammerlaenderHeadpoints = [['[yellow]Nur bei überwiegend privater Nutzung[/yellow]', '->']];
const ammerlaenderHeadpointSettings = {
  Ammerlaender: ammerlaenderHeadpoints,
  AmmerlaenderSchutzbrief: ammerlaenderHeadpoints,
  AmmerlaenderBike: ammerlaenderHeadpoints,
  AmmerlaenderRep: ammerlaenderHeadpoints,
  AmmerlaenderRepSchutzbrief: ammerlaenderHeadpoints,
  AmmerlaenderExcellent: ammerlaenderHeadpoints,
  AmmerlaenderExcellentBike: ammerlaenderHeadpoints,
  AmmerlaenderExcellentPedelec: ammerlaenderHeadpoints,
  AmmerlaenderExcellentPlusBike: ammerlaenderHeadpoints,
  AmmerlaenderExcellentPlusPedelec: ammerlaenderHeadpoints,
};

const PRESETS = {
  ammlntv: {
    mode: 'bydevkey',
    ebike: ammlntvSettings,
    bike: ammlntvSettings,
    _none: ammlntvSettings,
    headpoints: {
      ...ammerlaenderHeadpointSettings,
    },
  },
  schnellrechner: {
    mode: 'bydevkey',
    ebike: schnellrechnerSettings,
    bike: schnellrechnerSettings,
    _none: schnellrechnerSettings,
    headpoints: {
      ...ammerlaenderHeadpointSettings,
    },
  },
};

PRESETS['hepster'] = PRESETS['hepsterbase'] = PRESETS['schnellrechner'];

PRESETS['zundapp'] = {
  ...PRESETS['schnellrechner'],
  ebike: { ...PRESETS['schnellrechner'].ebike, filterkey: '' },
  bike: { ...PRESETS['schnellrechner'].bike, filterkey: '' },
};

export const updatePreset = (presetName) => {
  return async (dispatch, getState) => {
    const devMode = getState().devicemode.devkey;

    if (typeof PRESETS[presetName] === 'undefined') {
      // fallback "schnellrechner" if preset is not found
      console.warn('using preset fallback');
      presetName = 'schnellrechner';
    }
    const preset = PRESETS[presetName];
    dispatch({ type: 'PRESET_LOADED', preset, devMode, name: presetName });
  };
};

const copyPropIfSet = (source, target, prop, onlyNotEmpty = false) => {
  if (prop in source) {
    if(onlyNotEmpty && !source[prop]) return;
    target[prop] = source[prop];
  }
};

export const updatePresetIdState = (state) => {
  return { type: 'SET_PRESET_ID_STATE', state };
};

export const updatePresetUserData = (userData) => {
  const filteredUserData = { ...userData };

  if (!filteredUserData.street_number && filteredUserData.address) {
    const adressFilter = /^([\wß]*)\s?(\d*[a-zA-Z]?)$/gm;
    const match = adressFilter.exec(filteredUserData.address);
    if (match) {
      filteredUserData.street = match[1];
      filteredUserData.street_number = match[2];
    } else {
      filteredUserData.street = filteredUserData.address;
    }
  }

  return { type: 'PRESET_USERDATA', userData: filteredUserData };
};

async function loadPresetIdOptions(presetData, dispatch) {
  let customOptions: any[] = [];
  if (presetData.hideEnsResultFilterBox) {
    customOptions = [...customOptions, 'hideEnsResultFilterBox'];
  }
  if (presetData.hideOlderThan12Months) {
    customOptions = [...customOptions, 'hideOlderThan12Months'];
  }
  await dispatch({
    type: 'SET_CUSTOM_OPTIONS',
    options: customOptions,
  });
}

export const loadPresetId = (presetId, devicemodeOverride) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'PRESET_ID_LOADING' });

    const presetData = await fetch(getUrl(`/wp-json/ens/v1/presetId/${presetId}?setCookie=1`)).then((o) => o.json());

    // Set cookie manually, because of blocked third party cookies
    if(presetData.refererId) {
      document.cookie = `refererId=${presetData.refererId}; path=/; Expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure`;
    }

    if (presetData.error || !presetData) {
      await dispatch({ type: 'PRESET_ID_LOADED', data: null });
      await dispatch(updatePresetIdState('loading_finished'));
      return;
    }

    window.ensPresetData = presetData;

    if (presetData.refererId) {
      window.ensRefererOverride = presetData.refererId;
    }

    const devicemode = devicemodeOverride || presetData.selectedDeviceKey;
    if (devicemode) {
      await dispatch(selectDevMode(devicemode, true));
    }

    if (presetData.preset) {
      await dispatch(updatePreset(presetData.preset));
    }

    const userDataOverride = {};

    copyPropIfSet(presetData, userDataOverride, 'voucherCode', true);

    Object.keys(userDataOverride).forEach((udKey) => {
      dispatch({
        type: 'CHANGE_USER_INPUT',
        ...getUserInputEventDebugInfo(),
        value: {
          fieldName: udKey,
          fieldValue: userDataOverride[udKey],
        },
      });
    });

    const userDataBikeOverride = {};
    copyPropIfSet(presetData, userDataBikeOverride, 'rahmennummenNachreichen');
    copyPropIfSet(presetData, userDataBikeOverride, 'rechnugnsnummer');

    const bikeIds = Object.keys(getState().userData.bikes);
    bikeIds.forEach((bikeId) => {
      Object.keys(userDataBikeOverride).forEach((overrideProp) => {
        if (!userDataBikeOverride[overrideProp]) {
          return;
        }
        dispatch({
          type: 'CHANGE_USER_INPUT',
          ...getUserInputEventDebugInfo(),
          bikeId: bikeId,
          value: {
            fieldName: overrideProp,
            fieldValue: userDataBikeOverride[overrideProp],
          },
        });
      });
    });

    await loadPresetIdOptions(presetData, dispatch);

    await dispatch({
      type: 'PRESET_ID_LOADED',
      data: presetData,
      presetId: presetId,
    });
    
    await dispatch(updatePresetIdState('loading_finished'));

    await dispatch(applyDefaultUserData());

    if (presetData.angeboteDirektLaden) {
      if (presetData.angebotsansichtFahrradpreis) {
        const bikeIds = Object.keys(getState().userData.bikes);
        bikeIds.forEach((bikeId) => {
          dispatch({
            type: 'CHANGE_USER_INPUT',
            ...getUserInputEventDebugInfo(),
            bikeId: bikeId,
            value: {
              fieldName: 'price',
              fieldValue: presetData.angebotsansichtFahrradpreis,
            },
          });
        });
      }

      const bikeIds = Object.keys(getState().userData.bikes);
      if (
        presetData.angebotsansichtFahrradpreis ||
        bikeIds.every((bikeId) => {
          return getState().userData.bikes[bikeId].price;
        })
      ) {
        await dispatch({
          type: 'CHANGE_USER_INPUT',
          ...getUserInputEventDebugInfo(),
          value: {
            fieldName: 'beratungsverzicht',
            fieldValue: true,
          },
        });

        await dispatch(requestOffers());
      } else {
        Audit(
          'angeboteDirektLaden wurde nicht ausgeführt, da nicht alle Geräte einen Preis haben',
          0,
          'client:presetId'
        );
      }
    }
  };
};

import requestEnsurance from '../data/requestEnsurance';
import { getUrl } from '../shared/getUrl';

let updateDebounce = null;
const debounceTime = 2000;

export const filterVoucher = (fieldName, fieldValue) => {
  return (dispatch, getState) => {
    if (fieldName !== 'voucherCode') {
      return;
    }

    if (updateDebounce) {
      clearTimeout(updateDebounce);
    }

    dispatch({ type: 'ENS_VOUCHER_LOADING' });

    updateDebounce = setTimeout(async () => {
      const result = await fetch(getUrl('/wp-json/ens/v1/voucher/' + encodeURI(fieldValue)), {
        method: 'get'
      }).then((o) => o.json());

      const oldCode = getState().voucher?.voucher?.code;
      const oldState = getState().voucher?.voucher?.valid;
      const newValidValid = result && result.valid;

      await dispatch({
        type: 'ENS_VOUCHER_LOADED',
        voucher: result,
        valid: newValidValid
      });

      if (
        getState().checkout.offer?.name &&
        (oldState !== newValidValid || (newValidValid && oldCode !== result?.voucher?.code))
      ) {
        dispatch(requestEnsurance());
      }
    }, debounceTime);
  };
};

import { createContext, useContext } from 'react';
import { EnsProcessDefinition } from './EnsProcess';

export const ensProcessContext = createContext<EnsProcessDefinition<{}>>(null);

export const EnsProcessContextProvider = ensProcessContext.Provider;

export const useEnsProcessContext = () => {
  return useContext(ensProcessContext);
};

export const useIsInEnsProcessContext = () => {
  return !!useContext(ensProcessContext);
};

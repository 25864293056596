import { useCallback } from 'react';
import { type EnsProcessStep } from './EnsProcess';
import { useAppDispatch } from '@/hooks';
import { useEnsProcessContext } from './DefaultEnsProcessContext';
import { submitGeneral } from '@/actions/progress';

export const useNextEvent = (stepName: string) => {
  const dispatch = useAppDispatch();

  const currentProcess = useEnsProcessContext();
  const step = currentProcess.processSteps[stepName] as EnsProcessStep;

  return useCallback(() => {
    const defaultAction = (_) => {
      const validationFuncs = step.validate;

      const nextStep =
        step.nextStep ||
        (() => {
          const stepKeys = Object.keys(currentProcess.processSteps);
          const currentStepIndex = stepKeys.indexOf(stepName);
          return stepKeys[currentStepIndex + 1];
        })();

      return dispatch(submitGeneral(validationFuncs, nextStep, null));
    };

    if (step.nextStepAction) step.nextStepAction(dispatch, defaultAction);
    else defaultAction(dispatch);
  }, [step, stepName, currentProcess]);
};

import { Box, Group, Text } from '@mantine/core';
import numeral from 'numeral';
import { Price2 } from '@unserkunde/enscompare-components';
import React from 'react';
import { OfferType } from '@/reducer/checkout';
import { CustomProductPrice } from '@/reducer/customProducts/customProducts.types';

export type EnsPaymentPeriod = 'monthly' | 'quarterly' | 'halfyearly' | 'yearly' | 'once';

export type EnsPriceType = 'brutto' | 'netto' | 'unknown';

export class EnsPriceInfo {
  public period: EnsPaymentPeriod;
  public type: EnsPriceType;

  public amount: number = 0;

  constructor(amount: number = 0, period: EnsPaymentPeriod, type: EnsPriceType = 'unknown') {
    this.period = period;
    this.type = type;
    this.amount = amount;
  }

  static empty(): EnsPriceInfo {
    return new EnsPriceInfo(0, 'yearly', 'unknown');
  }

  static fromCustomProductPrice(price: CustomProductPrice): EnsPriceInfo {
    let period: EnsPaymentPeriod = null;

    if (price.priceType === 'yearly') period = 'yearly';
    if (price.priceType === 'monthly') period = 'monthly';
    if (price.priceType === 'quarterly') period = 'quarterly';
    if (price.priceType === 'halfyearly') period = 'halfyearly';
    if (price.priceType === 'once') period = 'once';
    if (price.priceType === 'deposit') period = 'once';

    return new EnsPriceInfo(price.price, period, 'unknown');
  }

  static fromStripePriceInfo(product): EnsPriceInfo {
    let period: EnsPaymentPeriod = 'yearly';

    if (product.price.recurring.interval === 'month' && product.price.recurring.interval_count === 1) {
      period = 'monthly';
    } else if (product.price.recurring.interval === 'month' && product.price.recurring.interval_count === 3) {
      period = 'quarterly';
    } else if (product.price.recurring.interval === 'month' && product.price.recurring.interval_count === 6) {
      period = 'halfyearly';
    }

    return new EnsPriceInfo(product.price.unit_amount / 100, period, 'unknown');
  }

  static periodFromOffer(offer: OfferType): EnsPaymentPeriod {
    let period: EnsPaymentPeriod = undefined;
    switch (offer.period?.toString()) {
      case '1':
        period = 'yearly';
        break;
      case '2':
        period = 'halfyearly';
        break;
      case '4':
        period = 'quarterly';
        break;
      case '6':
        period = 'halfyearly';
        break;
      case '12':
        period = 'monthly';
        break;
      case 'once':
        period = 'once';
        break;
      default:
        throw new Error('Unknown period: ' + offer.period);
    }

    return period;
  }

  getPeriodDisplay(): string {
    switch (this.period) {
      case 'monthly':
        return 'Monatlich';
      case 'quarterly':
        return 'Vierteljährlich';
      case 'halfyearly':
        return 'Halbjährlich';
      case 'yearly':
        return 'Jährlich';
      case 'once':
        return 'Einmalig';
      default:
        return this.period satisfies never;
    }
  }

  public add(other: EnsPriceInfo | null): EnsPriceInfo | null {
    if (other === null) return null;

    if (this.period !== other.period && this.period !== 'once' && other.period !== 'once') return null;

    if (this.type !== other.type) return null;

    return new EnsPriceInfo(this.amount + other.amount, this.period === 'once' ? other.period : this.period, this.type);
  }

  public displayFormat() {
    return numeral(this.amount).format('0.00');
  }

  public toGroupDisplay(productName: string): React.ReactNode {
    return (
      <tr>
        <td>
          <Text>{productName}</Text>
        </td>
        <td>
          <Price2.Price label={getEnsPaymentPeriodDisplay(this.period)}>{this.displayFormat()} €</Price2.Price>
        </td>
      </tr>
    );
  }
}
